import React from 'react';
import { captureConsoleIntegration } from '@sentry/integrations';
import {
  browserTracingIntegration,
  init,
  replayIntegration,
  ErrorBoundary as SentryErrorBoundary,
  browserProfilingIntegration
} from '@sentry/react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import AssessmentProvider from 'services/context/assessmentContext/assessmentContext';
import ThemeProvider from 'services/context/themeContext/themeContext';
import App from 'containers/app/App';
import MaintenanceBanner from 'containers/errorPage/MaintenanceBanner';
import { IS_MAINTENANCE_SCHEDULED } from 'utils/constants/constants';
import { RefinerTracker } from 'utils/libs/refiner/refiner';
import 'index.css';
import 'styles/flexboxgrid.css';
import { name, version } from '../package.json';
import ErrorPage from 'containers/errorPage/ErrorPage';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

console.log('You are using Correlation One ' + name + ' version: ' + version);

init({
  dsn:
    'https://ad424882bb764cdd861cbf61fea0dfcc@o1174160.ingest.sentry.io/6447926',
  integrations: [
    browserTracingIntegration(),
    captureConsoleIntegration({
      levels: ['error']
    }),
    replayIntegration({
      sessionSampleRate: 0,
      errorSampleRate: 1.0,
      maskAllText: false
    }),
    browserProfilingIntegration()
  ],
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.NODE_ENV === 'production',
  release: version,
  profilesSampleRate: 1.0,
  tracesSampleRate: 1.0
});

const favicon = document.getElementById('favicon');
const isTalentstat = origin.includes(process.env.REACT_APP_TALENTSTAT_DOMAIN);

if (isTalentstat)
  favicon.href =
    'https://storage.googleapis.com/c1-assessments-assets/ts-favicon.ico';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_ID
  });

  ReactDOM.render(
    <LDProvider>
      <AssessmentProvider>
        {IS_MAINTENANCE_SCHEDULED && <MaintenanceBanner />}
        <ThemeProvider>
          <Router>
            <RefinerTracker />
            <SentryErrorBoundary
              fallback={() => (
                <ErrorPage error={{ message: 'Something went wrong' }} />
              )}
            >
              <App />
            </SentryErrorBoundary>
          </Router>
        </ThemeProvider>
      </AssessmentProvider>
    </LDProvider>,
    document.getElementById('root')
  );
})();
